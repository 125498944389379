import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Pin marks",
  "author": "Stefan Huber",
  "date": "2018-11-05",
  "layout": "post",
  "draft": false,
  "path": "/posts/pin-marks/",
  "tags": ["TagOne", "TagTwo"],
  "description": "In the workshop at the School of Design in Zurich I found some pin marks on letters who indicate their orgin in Leipzig."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Today I found in the workshop some cases with «Elementarschmuck»
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/623996f469f7aaaed7ba71c90af34d3d/9568a/IMG_8479.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAECAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAeLUzuqExyZ9Siv/xAAcEAEAAgIDAQAAAAAAAAAAAAABABECEgMhIjL/2gAIAQEAAQUCtFyyhad3TAZ62dpg9ZPu2cXz/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8BIf/EABsQAAEEAwAAAAAAAAAAAAAAAAABEBExICFC/9oACAEBAAY/AsKZYk6Nilt//8QAHBAAAgIDAQEAAAAAAAAAAAAAAAERITFBYXGB/9oACAEBAAE/IXCKWlwUu18LptjsicnQRKlDwEJ0hGWJGjbfg5W7Kshpvdn/2gAMAwEAAgADAAAAEIMt/f/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxDWFlg0v//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIRH/2gAIAQIBAT8QyQtbLxv/xAAdEAEAAgIDAQEAAAAAAAAAAAABABEhMUFRcZGx/9oACAEBAAE/EETIdL+zAAXszfVdYAgFZZXzFHL+y5sF6lrmvogUTub/AGU0KuVtAoRlqJ2AUbpjMtXJ8J//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "workshop impressions",
            "title": "workshop impressions",
            "src": "/static/623996f469f7aaaed7ba71c90af34d3d/d9c39/IMG_8479.jpg",
            "srcSet": ["/static/623996f469f7aaaed7ba71c90af34d3d/20e5d/IMG_8479.jpg 450w", "/static/623996f469f7aaaed7ba71c90af34d3d/8e1fc/IMG_8479.jpg 900w", "/static/623996f469f7aaaed7ba71c90af34d3d/d9c39/IMG_8479.jpg 1800w", "/static/623996f469f7aaaed7ba71c90af34d3d/e3cde/IMG_8479.jpg 2700w", "/static/623996f469f7aaaed7ba71c90af34d3d/9568a/IMG_8479.jpg 3024w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Patterns for security prints
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a5ebb7b8796a237db4dc7ccf6fac33ec/9568a/IMG_8474.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDAQT/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAFKOe6arPLTQ0cH/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECMhESIUH/2gAIAQEAAQUCk3udHf1Ilcwx3Qj/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwFjP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/ASE//8QAGBABAQADAAAAAAAAAAAAAAAAARAAITH/2gAIAQEABj8CdudjWt//xAAcEAADAQACAwAAAAAAAAAAAAAAARExIUFRkaH/2gAIAQEAAT8hgezyVt69lD9K0drkRBKOMUD5hDB//9oADAMBAAIAAwAAABDLJ33/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/EE6yw6yF/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERITH/2gAIAQIBAT8QRRYKTg2IVP/EAB8QAQACAgICAwAAAAAAAAAAAAEAESExQVFhsXGh0f/aAAgBAQABPxA1KrAGVG8VLyWl15du17hK27WkbEqXgAxEmsRUU5r1C0E648Sgqq+T9hCrp6gNCcj9TZg28eZ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "workshop impressions",
            "title": "workshop impressions",
            "src": "/static/a5ebb7b8796a237db4dc7ccf6fac33ec/d9c39/IMG_8474.jpg",
            "srcSet": ["/static/a5ebb7b8796a237db4dc7ccf6fac33ec/20e5d/IMG_8474.jpg 450w", "/static/a5ebb7b8796a237db4dc7ccf6fac33ec/8e1fc/IMG_8474.jpg 900w", "/static/a5ebb7b8796a237db4dc7ccf6fac33ec/d9c39/IMG_8474.jpg 1800w", "/static/a5ebb7b8796a237db4dc7ccf6fac33ec/e3cde/IMG_8474.jpg 2700w", "/static/a5ebb7b8796a237db4dc7ccf6fac33ec/9568a/IMG_8474.jpg 3024w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`And a case with all the same patern
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c0840a3888df9caa017160a5d05a83dc/9568a/IMG_8482.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDBAH/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQD/2gAMAwEAAhADEAAAAZT08CsSNGStYlMox//EABwQAAICAwEBAAAAAAAAAAAAAAECAAMQESESMv/aAAgBAQABBQKwafpifByx6TB51WoaGtcf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEQ/9oACAEDAQE/AVTP/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAQEf/aAAgBAgEBPwG5KZ//xAAaEAACAwEBAAAAAAAAAAAAAAAAARARITEg/9oACAEBAAY/ArR1i8ZCs05H/8QAHhAAAgICAgMAAAAAAAAAAAAAAAERITFBUXFhgdH/2gAIAQEAAT8h1pU0aGwp0iS/REaJpdCkngN5J9yDAZF19Dyf/9oADAMBAAIAAwAAABDoM43/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEQ/9oACAEDAQE/EM4Gmzf/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/ELDQk4xH/8QAHhABAAMBAAEFAAAAAAAAAAAAAQARITHRQWFxgaH/2gAIAQEAAT8Q9cCMDHdinSXo1UNBLYAMVeq92U1Y+SLAFCmRCuHkwRgPuWp8rl3wc1JZD9vMTYvmT//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "workshop impressions",
            "title": "workshop impressions",
            "src": "/static/c0840a3888df9caa017160a5d05a83dc/d9c39/IMG_8482.jpg",
            "srcSet": ["/static/c0840a3888df9caa017160a5d05a83dc/20e5d/IMG_8482.jpg 450w", "/static/c0840a3888df9caa017160a5d05a83dc/8e1fc/IMG_8482.jpg 900w", "/static/c0840a3888df9caa017160a5d05a83dc/d9c39/IMG_8482.jpg 1800w", "/static/c0840a3888df9caa017160a5d05a83dc/e3cde/IMG_8482.jpg 2700w", "/static/c0840a3888df9caa017160a5d05a83dc/9568a/IMG_8482.jpg 3024w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/173bde4eee787e0bb8b0489b2500b51c/9568a/IMG_8492.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAwL/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAaVPzm1kwM0ZmaB//8QAGhAAAwADAQAAAAAAAAAAAAAAAAESERMhIP/aAAgBAQABBQKjYWZ4uslEox4//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwJP/8QAGhABAAMAAwAAAAAAAAAAAAAAAQAQESExQf/aAAgBAQABPyFYxVXOouGtKkTyHVf/2gAMAwEAAgADAAAAEHf7Qf/EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxBw/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAGxABAAMBAQEBAAAAAAAAAAAAAQARITFBUYH/2gAIAQEAAT8QtwzTstm+S6Wl/Yal8u/2OvaiUUbGdiuS17KgEvZ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "workshop impressions",
            "title": "workshop impressions",
            "src": "/static/173bde4eee787e0bb8b0489b2500b51c/d9c39/IMG_8492.jpg",
            "srcSet": ["/static/173bde4eee787e0bb8b0489b2500b51c/20e5d/IMG_8492.jpg 450w", "/static/173bde4eee787e0bb8b0489b2500b51c/8e1fc/IMG_8492.jpg 900w", "/static/173bde4eee787e0bb8b0489b2500b51c/d9c39/IMG_8492.jpg 1800w", "/static/173bde4eee787e0bb8b0489b2500b51c/e3cde/IMG_8492.jpg 2700w", "/static/173bde4eee787e0bb8b0489b2500b51c/9568a/IMG_8492.jpg 3024w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Some of them have markings on the side`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3c314675588668a2b4ec0108e34c0dda/9568a/IMG_8490.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAdJhZvQZBSpDGH//xAAcEAACAgIDAAAAAAAAAAAAAAAAAQIRAyAhIjL/2gAIAQEAAQUCssUiXrEnJlcw6NLT/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAIDFR/9oACAEBAAY/AnsLJ7X/xAAaEAEBAQEBAQEAAAAAAAAAAAABABFBITFR/9oACAEBAAE/IVluTL0tRhyL/HDts/TIpgOP28Lpdi//2gAMAwEAAgADAAAAEEvlMf/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABoQAAMBAQEBAAAAAAAAAAAAAAABETEhQWH/2gAIAQEAAT8QYSnbRReiNFFVcb5Ri2xLUFV89H12bU0dOIR4ILZX9K9PBMYR/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "workshop impressions",
            "title": "workshop impressions",
            "src": "/static/3c314675588668a2b4ec0108e34c0dda/d9c39/IMG_8490.jpg",
            "srcSet": ["/static/3c314675588668a2b4ec0108e34c0dda/20e5d/IMG_8490.jpg 450w", "/static/3c314675588668a2b4ec0108e34c0dda/8e1fc/IMG_8490.jpg 900w", "/static/3c314675588668a2b4ec0108e34c0dda/d9c39/IMG_8490.jpg 1800w", "/static/3c314675588668a2b4ec0108e34c0dda/e3cde/IMG_8490.jpg 2700w", "/static/3c314675588668a2b4ec0108e34c0dda/9568a/IMG_8490.jpg 3024w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The pin marks (Gießmarke) indicate they are comming from `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Schelter_%26_Giesecke_Type_Foundry"
      }}>{`Schelter & Giesecke`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      